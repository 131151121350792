import { useQueryClient, type InfiniteData, QueryFilters } from '@tanstack/react-query'
import { useWallet } from '@txnlab/use-wallet-react'
import { useRouter } from 'next/router'
import * as React from 'react'
import { usePostSendFromVault } from '@/api/hooks/usePostSendFromVault'
import { usePostSendToVault } from '@/api/hooks/usePostSendToVault'
import { getAssetUpdate } from '@/components/DetailView/Assets/Assets.utils'
import { isAssetNft as _isAssetNft } from '@/helpers/utilities'
import { isVaultsSupported } from '@/helpers/versions'
import useErrorToast from '@/hooks/useErrorToast'
import { useOptimisticUpdateStore } from '@/store/index'
import type { NfdRecord } from '@/api/api-client'
import type { InfiniteAssetsQueryResult } from '@/api/hooks/useInfiniteAssets'
import type { Field } from '@/components/manage/AvatarBanner/ManageAvatarBanner'
import type { ReceiverType } from '@/components/NfdLookup/NfdLookup.types'
import type { OnSuccessParams } from '@/components/SendModal/SendModal.types'
import type { Asset } from '@/types/assets'

interface UseAssetPreview {
  nfd: NfdRecord
  asset: Asset
  view: 'grid' | 'list'
}

export function useAssetPreview({ nfd, asset, view }: UseAssetPreview) {
  const [isDetailsOpen, setIsDetailsOpen] = React.useState(false)
  const [isSendModalOpen, setIsSendModalOpen] = React.useState(false)
  const [isAccountsOpen, setIsAccountsOpen] = React.useState(false)
  const [receiver, setReceiver] = React.useState<string | undefined>()
  const [receiverType, setReceiverType] = React.useState<ReceiverType | undefined>()

  const isList = view === 'list'
  const badgeRef = React.useRef<HTMLButtonElement>(null)
  const listBadgeRef = React.useRef<HTMLButtonElement>(null)

  const { activeWalletAddresses } = useWallet()
  const router = useRouter()
  const handleError = useErrorToast()

  const isAssetInVault = React.useMemo(
    () => asset.amounts.some((a) => a.account === nfd.nfdAccount),
    [asset.amounts, nfd.nfdAccount]
  )

  const isAssetNotInVault = React.useMemo(
    () => asset.amounts.some((a) => a.account !== nfd.nfdAccount),
    [asset.amounts, nfd.nfdAccount]
  )

  const canSign = React.useCallback(
    (account: string | undefined) =>
      (!!account && activeWalletAddresses?.includes(account)) || false,
    [activeWalletAddresses]
  )

  const senders = React.useMemo(() => {
    return asset.amounts.reduce((acc, assetAmount) => {
      // exclude sender if same as receiver
      if (receiver === nfd.name) {
        if (receiverType === 'nfdVault') {
          if (assetAmount.account === nfd.nfdAccount) {
            return acc
          }
        } else if (receiverType === 'account') {
          if (assetAmount.account === nfd.depositAccount) {
            return acc
          }
        }
      }

      if (assetAmount.account === nfd.nfdAccount && canSign(nfd.owner)) {
        // asset is in vault and NFD owner is connected
        acc.push(nfd)
      } else if (canSign(assetAmount.account)) {
        // asset is in connected account
        acc.push(assetAmount.account)
      }

      return acc
    }, [] as Array<NfdRecord | string>)
  }, [asset.amounts, canSign, nfd, receiver, receiverType])

  const totalAmount = React.useMemo(() => {
    return asset.amounts.reduce((acc, amount) => acc + amount.amount, 0)
  }, [asset.amounts])

  const isNfdOwnerConnected = React.useMemo(() => canSign(nfd.owner), [canSign, nfd.owner])

  const isAssetNft = _isAssetNft(asset.totalCreated, asset.decimals)
  const isAssetNfd = asset.unitName?.toLowerCase() === 'nfd'

  const canSendAsset = !isAssetNfd && senders.length > 0
  const canMoveAsset = canSendAsset && isNfdOwnerConnected && isVaultsSupported(nfd)
  const canMoveToVault = canMoveAsset && isAssetNotInVault
  const canMoveToDepositAccount = canMoveAsset && isAssetInVault

  const showAccountsBadge =
    asset.amounts.some((amount) => amount.account === nfd.nfdAccount) || asset.amounts.length > 1

  const addOptimisticUpdate = useOptimisticUpdateStore((state) => state.addOptimisticUpdate)
  const queryClient = useQueryClient()

  const handleSendOptimisticUpdate = (params: OnSuccessParams) => {
    const filters: QueryFilters = {
      predicate: (query) =>
        Array.isArray(query.queryKey) &&
        query.queryKey[0].startsWith('nfd-assets') &&
        query.queryKey[1] === nfd.name
    }

    const queries = queryClient.getQueriesData<InfiniteData<InfiniteAssetsQueryResult>>(filters)

    queries.forEach(([queryKey, cachedData]) => {
      if (cachedData) {
        const newData = {
          ...cachedData,
          pages: cachedData.pages.map((page) => ({
            ...page,
            assets: page.assets
              .map((cachedAsset, i) => {
                if (cachedAsset.id === asset.id) {
                  const [operation, record] = getAssetUpdate({
                    nfd,
                    asset: cachedAsset,
                    params,
                    queryKey
                  })

                  addOptimisticUpdate<Asset>({
                    queryKey,
                    record,
                    uniqueKey: 'id',
                    operation,
                    pageIndex: i
                  })

                  if (operation === 'remove') {
                    return null
                  } else {
                    return record
                  }
                }

                return cachedAsset
              })
              .filter(Boolean)
          }))
        }

        queryClient.setQueryData(queryKey, newData)
      }
    })
  }

  const { mutateAsync: moveAssetToVault } = usePostSendToVault({
    onSuccess: (data, params) => {
      handleSendOptimisticUpdate(params)
    }
  })

  const handleClickMoveToVault = async () => {
    if (!canMoveToVault) {
      throw new Error('Asset cannot be moved to vault')
    }

    if (asset.totalCreated > 1) {
      setReceiver(nfd.name)
      setReceiverType('nfdVault')
      handleOpenSendModal()
    } else {
      try {
        const body = {
          sender: asset.amounts[0].account,
          assets: [asset.id],
          amount: 1,
          optInOnly: false
        }

        await moveAssetToVault({ name: nfd.name, body })
      } catch (error) {
        handleError(error)
      }
    }
  }

  const { mutateAsync: moveAssetToDepositAccount } = usePostSendFromVault({
    onSuccess: (data, params) => {
      handleSendOptimisticUpdate(params)
    }
  })

  const handleClickMoveToDepositAccount = async () => {
    if (!canMoveToDepositAccount || !nfd.depositAccount) {
      throw new Error('Asset cannot be moved to deposit account')
    }

    if (asset.totalCreated > 1) {
      setReceiver(nfd.name)
      setReceiverType('account')
      setTimeout(() => handleOpenSendModal(), 0)
    } else {
      try {
        const body = {
          sender: nfd.owner as string,
          receiver: nfd.depositAccount,
          assets: [asset.id],
          amount: 1
        }

        await moveAssetToDepositAccount({ name: nfd.name, body })
      } catch (error) {
        handleError(error)
      }
    }
  }

  const handleOpenSendModal = () => {
    if (isDetailsOpen) {
      setIsDetailsOpen(false)
      setTimeout(() => setIsSendModalOpen(true), 600)
    } else {
      setIsSendModalOpen(true)
    }
  }

  const handleResetReceiver = () => {
    setReceiver(undefined)
    setReceiverType(undefined)
  }

  const handleClickAccounts = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setIsAccountsOpen(true)
    setIsDetailsOpen(true)
  }

  const handleOpenDetails = (isOpen: boolean) => {
    setIsDetailsOpen(isOpen)
    if (!isOpen) {
      setTimeout(() => setIsAccountsOpen(false), 600)
    }
  }

  const handleClickFilterAccount = (account: string) => {
    handleOpenDetails(false)

    const isVault = account === nfd.nfdAccount
    const show = isVault ? 'vault' : 'owned'

    router.push({
      pathname: `/name/${nfd.name}`,
      query: {
        view: 'assets',
        show,
        ...(!isVault && { accounts: account })
      }
    })
  }

  return {
    isList,
    badgeRef,
    listBadgeRef,
    isDetailsOpen,
    isAccountsOpen,
    isSendModalOpen,
    setIsSendModalOpen,
    senders,
    receiver,
    receiverType,
    handleOpenSendModal,
    handleSendOptimisticUpdate,
    handleClickMoveToVault,
    handleClickMoveToDepositAccount,
    handleResetReceiver,
    handleOpenDetails,
    handleClickAccounts,
    handleClickFilterAccount,
    canSendAsset,
    canMoveToVault,
    canMoveToDepositAccount,
    showAccountsBadge,
    totalAmount,
    isAssetNft
  }
}

interface UseAvatarBannerModal {
  nfd: NfdRecord
  asset: Asset
}

export function useAvatarBannerModal({ nfd, asset }: UseAvatarBannerModal) {
  const [selectedField, setSelectedField] = React.useState<Field | null>(null)
  const { activeWalletAddresses } = useWallet()

  const canSign = (account: string | undefined) =>
    (!!account && activeWalletAddresses?.includes(account)) || false

  const isAssetNft = _isAssetNft(asset.totalCreated, asset.decimals)
  const isAssetNfd = asset.unitName?.toLowerCase() === 'nfd'
  const isNfdOwnerConnected = canSign(nfd.owner)
  const canSetField = isAssetNft && !isAssetNfd && isNfdOwnerConnected

  return {
    selectedField,
    setSelectedField,
    canSetField
  }
}
