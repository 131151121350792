import { Disclosure } from '@headlessui/react'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
// import dynamic from 'next/dynamic'
import { useCallback, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { AiFillBank } from 'react-icons/ai'
import { HiChevronUp, HiLink } from 'react-icons/hi'
import { RiTwitterFill } from 'react-icons/ri'
import Alert from 'components/Alert'
import Button from 'components/Button'
import ButtonLink from 'components/ButtonLink'
import Callout from 'components/Callout'
import AssetDetails from 'components/DetailView/Assets/AssetPreview/AssetDetails'
import TokenImage from 'components/DetailView/Assets/AssetPreview/TokenImage'
import DetailsSkeleton from 'components/DetailView/DetailsSkeleton'
import PurchaseLayout from 'components/Layout/Purchase'
import PriceTag from 'components/PriceTag'
import AssetMedia from 'components/AssetMedia'
import UserThumbnail from 'components/UserThumbnail'
import SalesHistory from 'components/SalesHistory'
import UsdPrice from 'components/UsdPrice'
import OffersHistory from 'components/OffersHistory'
import { ResultTag, ResultTags } from 'components/ResultTag'
import LoadingDots from 'components/LoadingDots'
import Tooltip from 'components/Tooltip'
// import AuctionPrice from './AuctionPrice'
// import AuctionTimeRemaining from './AuctionTimeRemaining'
// import AuctionBidHistory from './AuctionBidHistory'
import useVaultAssets from 'api/hooks/useVaultAssets'
import { TREASURY_ACCT } from 'src/data/constants'
import { getAvatarURL } from 'helpers/avatar'
import { copyToClipboard } from 'helpers/copyToClipboard'
import { formatAmount } from 'helpers/format'
import galleryImageLoader from 'helpers/galleryImageLoader'
import getTags from 'helpers/getTags'
import { clsxMerge, getNfdUrl, getTwitterShareLink, isAssetNft } from 'helpers/utilities'
import type { NfdRecord } from 'api/api-client'
import type { Asset } from 'types/assets'
import { getNfdVersion, meetsMinimumVersion } from 'helpers/versions'

dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)

// const AuctionSlider = dynamic(() => import('./AuctionSlider'))

interface BuyNowProps {
  nfd: NfdRecord
  // auction: NFDAuctionAndPrice | undefined | null
  onBuy: () => void
  isDisabled: boolean
  // onEscrowOffer: (offer: number) => void
  // isBidDisabled: boolean
  isPolling: boolean
}

export default function BuyNow({
  nfd,
  // auction,
  onBuy,
  isDisabled,
  // onEscrowOffer,
  // isBidDisabled,
  isPolling
}: BuyNowProps) {
  const { showVaultInfo, assets, isLoading, error } = useVaultAssets(nfd)

  const [isDetailsOpen, setIsDetailsOpen] = useState<Record<number, boolean> | null>(null)

  const isExpired = dayjs(nfd.timeExpires).isBefore(dayjs())
  const expiresSoon = dayjs(nfd.timeExpires).isBefore(dayjs().add(30, 'days'))

  const handleSetIsDetailsOpen = useCallback(
    (assetId: number, isOpen: boolean) => {
      setIsDetailsOpen(
        assets
          ? assets.reduce((acc, asset) => {
              if (asset.id === assetId) {
                acc[asset.id] = isOpen
              } else {
                acc[asset.id] = false
              }
              return acc
            }, {} as Record<number, boolean>)
          : null
      )
    },
    [assets]
  )

  useEffect(() => {
    if (assets) {
      setIsDetailsOpen(
        assets.reduce((acc, asset) => {
          acc[asset.id] = false
          return acc
        }, {} as Record<number, boolean>)
      )
    }
  }, [assets])

  const vaultAddress = nfd.nfdAccount

  const getVaultAmount = (asset: Asset) => {
    const vaultAmount = asset.amounts.find((amount) => amount.account === vaultAddress)?.amount

    if (!vaultAmount) return null

    return formatAmount(vaultAmount, { decimals: asset.decimals, maxLength: 10 })
  }

  const renderPreview = (asset: Asset) => {
    if (!isAssetNft(asset.totalCreated, asset.decimals) && !asset.imageUrl) {
      return <TokenImage />
    }

    return (
      <AssetMedia
        src={asset.imageUrl}
        alt={asset.id.toString()}
        className="object-cover w-full h-full"
        loader={galleryImageLoader}
        sizes="32px"
        fill
        options={{ showVideoIcon: false, stateBgColor: 'bg-gray-500/5 dark:bg-gray-500/5' }}
        videoJsOptions={{
          preload: isMobile ? 'auto' : 'metadata',
          controls: isMobile,
          fluid: true,
          fill: true
        }}
      />
    )
  }

  const renderAssetDetails = (asset: Asset) => {
    const isNft = isAssetNft(asset.totalCreated, asset.decimals)
    const totalAmount = asset.amounts.find((amount) => amount.account === vaultAddress)?.amount || 0

    return (
      <AssetDetails
        open={isDetailsOpen?.[asset.id] || false}
        setOpen={(open) => handleSetIsDetailsOpen(asset.id, open)}
        nfd={nfd}
        asset={asset}
        isNft={isNft}
        isAccountsOpen={false}
        totalAmount={totalAmount}
        handleClickSetField={() => null}
        handleClickSendAsset={() => null}
        handleClickMoveToVault={async () => void 0}
        handleClickMoveToDepositAccount={async () => void 0}
        handleClickFilterAccount={() => null}
        canSetField={false}
        canSendAsset={false}
        canMoveToVault={false}
        canMoveToDepositAccount={false}
      />
    )
  }

  const renderVaultAssets = () => {
    const showVaultAssets = showVaultInfo && !!assets && assets.length > 0

    if (!showVaultAssets) return null

    if (isLoading) {
      return (
        <div className="flex items-center justify-center h-[72px]">
          <LoadingDots />
        </div>
      )
    }

    if (error) {
      return (
        <div>
          <Alert type="error" title={`Error fetching vault assets`} error={error} />
        </div>
      )
    }

    return (
      <Callout theme="blue">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg px-1 py-2 text-left font-medium">
                <span className="inline-flex items-center">
                  <AiFillBank className="h-5 w-5 mr-2" aria-hidden="true" /> Vault Assets (
                  {assets.length})
                </span>
                <HiChevronUp
                  className={clsxMerge(open ? 'transform rotate-180' : '', 'h-6 w-6')}
                  aria-hidden="true"
                />
              </Disclosure.Button>
              <Disclosure.Panel>
                <div className="mt-6 space-y-2.5 max-h-48 overflow-y-auto pr-6">
                  {assets.map((asset) => (
                    <div key={asset.id}>
                      <button
                        type="button"
                        className="group flex items-center w-full"
                        onClick={() => handleSetIsDetailsOpen(asset.id, true)}
                      >
                        <div className="relative flex-shrink-0 w-8 h-8 rounded-full overflow-hidden bg-gray-500/10">
                          {renderPreview(asset)}
                        </div>
                        <div className="ml-3 flex-1 flex items-center justify-between min-w-0 text-sm font-medium text-gray-900 dark:text-gray-200">
                          <p className="truncate font-medium group-hover:text-brand-500">
                            {asset.name}
                          </p>
                          <span className="ml-4 font-mono">{getVaultAmount(asset)}</span>
                        </div>
                      </button>
                      {renderAssetDetails(asset)}
                    </div>
                  ))}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </Callout>
    )
  }

  if (isPolling) {
    return <DetailsSkeleton />
  }

  // const isAuctionLive =
  //   auction &&
  //   nfd.state === 'forSale' &&
  //   !!auction.auctionInfo?.endTime &&
  //   dayjs(auction.auctionInfo.endTime).add(5, 'seconds').isAfter(dayjs())
  const isAuctionLive = false

  const hideMakeOfferButton =
    nfd?.state === 'forSale' &&
    nfd?.saleType === 'auction' &&
    !isAuctionLive &&
    nfd.seller === TREASURY_ACCT

  return (
    <PurchaseLayout nfd={nfd}>
      <div className="md:grid md:grid-cols-[200px_1fr] md:gap-x-8 xl:grid-cols-[240px_1fr]">
        <div className="hidden md:block">
          <div className="relative md:w-full aspect-square rounded-lg bg-gray-200 overflow-hidden dark:bg-gray-800">
            <AssetMedia
              src={getAvatarURL(nfd)}
              alt={nfd.name}
              className="object-cover w-full h-full"
              priority
              sizes="240px"
              fill
            />
          </div>
        </div>

        <div>
          <div className="flex items-end justify-between space-x-3">
            <h1 className="text-2xl sm:text-3xl font-extrabold tracking-tight text-gray-900 truncate dark:text-gray-100">
              {nfd.name}
            </h1>

            {/* {isAuctionLive && (
              <span className="inline-flex align-middle items-center px-2 py-0.5 rounded text-xs font-semibold bg-amber-800 text-white uppercase shadow-md mb-1.5">
                <svg
                  className="-ml-0.5 mr-1.5 h-2 w-2 text-white animate-blink"
                  fill="currentColor"
                  viewBox="0 0 8 8"
                >
                  <circle cx={4} cy={4} r={3} />
                </svg>
                Live <span className="hidden sm:inline">&nbsp;Auction</span>
              </span>
            )} */}
          </div>
          <div className="flex items-start justify-between space-x-3">
            <div className="mt-2 flex items-center gap-x-2">
              {meetsMinimumVersion(nfd, '3') ? (
                <Tooltip
                  as="span"
                  text={dayjs(nfd.timeExpires).format('lll')}
                  className="translate-y-3"
                >
                  <p className="text-sm text-gray-700 dark:text-gray-400">
                    <ResultTag
                      label={isExpired ? 'Expired' : `Expires ${dayjs(nfd.timeExpires).fromNow()}`}
                      color={expiresSoon ? 'red' : 'gray'}
                      className={clsxMerge(expiresSoon ? 'font-bold' : '')}
                    />
                  </p>
                </Tooltip>
              ) : (
                <ResultTag label={`v${getNfdVersion(nfd)}`} color="gray" className="mt-1.5" />
              )}
              <ResultTags tags={getTags(nfd, { premium: true })} />
            </div>

            {/* {isAuctionLive && (
              <span className="flex items-center text-sm text-gray-500 font-medium">
                <span className="text-xs font-semibold uppercase tracking-wide text-gray-400 py-2 whitespace-nowrap translate-y-[1px]">
                  Ends in
                </span>
                <div
                  aria-hidden="true"
                  className="hidden w-px h-5 bg-gray-300 sm:block sm:ml-3 dark:bg-gray-700"
                />
                <AuctionTimeRemaining
                  auction={auction}
                  className="ml-2 sm:ml-3 font-bold text-base"
                  colorChange
                />
              </span>
            )} */}
          </div>

          <div className="mt-4 sm:mt-5 space-y-6">
            {/* {isAuctionLive ? (
              <>
                <div>
                  <h2 className="sr-only">Current price</h2>
                  <div className="flex items-center">
                    <AuctionPrice price={nfd.sellAmount} auction={auction} />
                    <UsdPrice price={nfd.sellAmount as number} className="text-right ml-2" />
                  </div>
                  <div className="mt-8 mb-2 flex">
                    <Button
                      className="flex-1 py-3 px-8 sm:w-full sm:max-w-sm"
                      onClick={onBuy}
                      size="lg"
                      variant="gradient"
                      disabled={isDisabled}
                    >
                      Buy now
                    </Button>
                  </div>
                  <div className="pt-12">
                    <AuctionSlider
                      nfd={nfd}
                      auction={auction}
                      onEscrowOffer={onEscrowOffer}
                      isBidDisabled={isBidDisabled}
                    />
                  </div>
                </div>
                <div>
                  <AuctionBidHistory nfd={nfd} auction={auction} />
                </div>
              </>
            ) : (
              <div>
                <h2 className="sr-only">Price</h2>
                <div className="flex items-center">
                  <PriceTag price={nfd.sellAmount as number} className="pl-3 pr-3 text-xl" />
                  <UsdPrice price={nfd.sellAmount as number} className="text-right ml-2" />
                </div>
              </div>
            )} */}
            <div>
              <h2 className="sr-only">Price</h2>
              <div className="flex items-center">
                <PriceTag price={nfd.sellAmount as number} className="pl-3 pr-3 text-xl" />
                <UsdPrice price={nfd.sellAmount as number} className="text-right ml-2" />
              </div>
            </div>

            {nfd.seller && !isAuctionLive && (
              <div>
                <h3 className="text-sm text-gray-500 mb-1">Seller</h3>
                <div className="flex">
                  <UserThumbnail address={nfd.seller} className="inline-flex min-w-0 pr-2" />
                </div>
              </div>
            )}

            {renderVaultAssets()}
          </div>

          <div className={clsxMerge(isAuctionLive ? 'hidden' : '')}>
            <div className="mt-8 flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-3">
              <Button
                className="flex-1 py-3 px-8 sm:w-full sm:max-w-sm"
                onClick={onBuy}
                size="lg"
                variant="gradient"
                disabled={isDisabled}
              >
                Buy now
              </Button>
              {!hideMakeOfferButton && (
                <ButtonLink
                  className="flex-1 py-3 px-8 sm:w-full"
                  href={`/offer/${nfd.name}`}
                  size="lg"
                  variant="default"
                >
                  Make an offer
                </ButtonLink>
              )}
            </div>
          </div>

          <section aria-labelledby="details-heading" className="mt-12">
            <h2 id="details-heading" className="sr-only">
              Additional details
            </h2>

            <div className="border-t divide-y divide-gray-200 dark:border-gray-750/75 dark:divide-gray-750/75">
              <div className="py-6">
                <div className="flex items-center space-x-3">
                  <Button
                    className="group"
                    icon={HiLink}
                    data-clipboard-text={getNfdUrl(nfd.name)}
                    onClick={copyToClipboard}
                  >
                    Copy link
                  </Button>
                  <a href={getTwitterShareLink(nfd.name, true)} target="_blank" rel="noreferrer">
                    <Button icon={RiTwitterFill}>Share to Twitter</Button>
                  </a>
                </div>
              </div>

              {!isAuctionLive && (
                <>
                  <div className="space-y-6 py-6">
                    <SalesHistory name={nfd.name} />
                    <OffersHistory name={nfd.name} />
                  </div>
                </>
              )}
            </div>
          </section>
        </div>
      </div>
    </PurchaseLayout>
  )
}
